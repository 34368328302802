import { createContext, ReactNode, useCallback, useMemo, useState } from 'react'

import { CapacityCommitmentFunded } from '../store/CapacityCommitmentFunded'
import { CapacityCommitmentRewards } from '../store/CapacityCommitmentRewards'

export type WithdrawStatus = 'waiting' | 'pending' | 'success' | 'failed'
type WithdrawType = 'collateral' | 'rewards'

interface WithdrawModalData {
  id: string
  type: WithdrawType
  title: string
  status: WithdrawStatus
  transactionHash?: string
  capacityCommitments: (CapacityCommitmentFunded | CapacityCommitmentRewards)[]
}

export const WithdrawModalContext = createContext<{
  data: WithdrawModalData | null
  setData: (data: WithdrawModalData) => void
  status?: WithdrawStatus
  type?: WithdrawType
  setStatus: (id: string, status: WithdrawStatus) => void
  setCurrentId: (id: string) => void
}>({
  data: null,
  setData: () => {},
  setStatus: () => {},
  setCurrentId: () => {},
})

export default function WithdrawModalProvider({
  children,
}: {
  children: ReactNode
}) {
  const [store, setStore] = useState<{
    [key: string]: WithdrawModalData | null
  }>({})
  const [currentId, setCurrentId] = useState('')

  const update = useCallback(
    (id: string, data: WithdrawModalData | null) => {
      if (id) setCurrentId(id)
      setStore({ ...store, [id]: data })
    },
    [setCurrentId, setStore, store],
  )

  const current = store[currentId] || null

  const value = useMemo(
    () => ({
      data: current,
      status: current?.status,
      type: current?.type,
      setData: (data: WithdrawModalData) => update(data.id, data),
      setStatus: (id: string, status: WithdrawStatus) => {
        const record = store[id]
        if (record && id) update(id, { ...record, status })
      },
      setCurrentId,
    }),
    [store, current, update, setCurrentId],
  )

  return (
    <WithdrawModalContext.Provider value={value}>
      {children}
    </WithdrawModalContext.Provider>
  )
}
